<template>
  <div>
    <div class="block_1 flex-col">
      <span class="text_1">助家政企业数字化，让家政生意不难做!</span>
      <span class="text_2">专业的家政管理系统</span>
      <span class="paragraph_1"
        >助力共赢的家政行业生态提供全场景、一站式的解决方案<br />满足家政企业各种需求</span
      >
      <div class="box_1 flex-col">
        <div class="text-wrapper_1 flex-col" @click="freeClick">
          立即咨询体验
        </div>
      </div>
    </div>
    <!--  -->
    <div class="block_2 flex-col pagewdOne" id="group_2">
      <div
        class="text-group_1 flex-col justify-between"
        :class="TwoShow ? 'animate__animated animate__zoomIn' : ''"
      >
        <span class="text_4">应用场景</span>
        <span class="paragraph_2"
          >家政系统是一套O2O家政平台解决方案，它能够与微信对接、拥有用户端小程序，可以帮助创业者<br />在不需要相关技术人员及大量资金投入的情况下，就能够轻松搭建并运营家政服务平台。</span
        >
      </div>
      <div class="block_3 flex-row justify-between">
        <div class="text-wrapper_2 flex-col">选择服务/人员</div>
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/31.png"
        />
        <div class="text-wrapper_3 flex-col">预约下单</div>
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/31.png"
        />
        <div class="text-wrapper_3 flex-col">抢/派订单</div>
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/31.png"
        />
        <div class="text-wrapper_3 flex-col">服务上门</div>
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/31.png"
        />
        <div class="text-wrapper_3 flex-col">二次收款/用户确认</div>
        <img
          class="image_1"
          referrerpolicy="no-referrer"
          src="../assets/31.png"
        />
        <div class="text-wrapper_3 flex-col">评价/投诉</div>
      </div>
      <div class="block_4 flex-row justify-between">
        <div class="block_5 flex-col">
          <img
            class="image_6"
            referrerpolicy="no-referrer"
            src="../assets/32.png"
          />
          <div class="section_1 flex-row justify-between">
            <div class="group_1 flex-col"></div>
            <div class="text-group_2 flex-col justify-between">
              <span class="text_11">招工管理</span>
              <span class="text_12">求职报名信息及时跟进</span>
            </div>
          </div>
          <div class="section_1 flex-row justify-between">
            <div class="group_1 flex-col"></div>
            <div class="text-group_2 flex-col justify-between">
              <span class="text_11">简历管理</span>
              <span class="text_12">快速登记，技能个性标注</span>
            </div>
          </div>
          <div class="section_1 flex-row justify-between">
            <div class="group_1 flex-col"></div>
            <div class="text-group_2 flex-col justify-between">
              <span class="text_11">风险查询</span>
              <span class="text_12">证件核验、涉诉/失信查询</span>
            </div>
          </div>
          <div class="section_1 flex-row justify-between">
            <div class="group_1 flex-col"></div>
            <div class="text-group_2 flex-col justify-between">
              <span class="text_11">家政保险</span>
              <span class="text_12">专业定制，价格低/保障高</span>
            </div>
          </div>
        </div>
        <div class="block_6 flex-col">
          <img
            class="image_7"
            referrerpolicy="no-referrer"
            src="../assets/36.png"
          />
          <div class="group_4 flex-row justify-between">
            <div class="box_2 flex-col"></div>
            <div class="text-group_6 flex-col justify-between">
              <span class="text_19">家政员搜索</span>
              <span class="text_20">为客户快速匹配阿姨</span>
            </div>
          </div>
          <div class="group_4 flex-row justify-between">
            <div class="box_2 flex-col"></div>
            <div class="text-group_6 flex-col justify-between">
              <span class="text_19">客户档案</span>
              <span class="text_20">客户登记，画像管理</span>
            </div>
          </div>
          <div class="group_4 flex-row justify-between">
            <div class="box_2 flex-col"></div>
            <div class="text-group_6 flex-col justify-between">
              <span class="text_19">订单管理</span>
              <span class="text_20">强大的订单管理体系</span>
            </div>
          </div>
          <div class="group_4 flex-row justify-between">
            <div class="box_2 flex-col"></div>
            <div class="text-group_6 flex-col justify-between">
              <span class="text_19">品控管理</span>
              <span class="text_20">各类订单客户评价查询</span>
            </div>
          </div>
        </div>
        <div class="block_8 flex-col">
          <img
            class="image_8"
            referrerpolicy="no-referrer"
            src="../assets/37.png"
          />
          <div class="block_9 flex-row justify-between">
            <div class="section_6 flex-col"></div>
            <div class="text-group_10 flex-col justify-between">
              <span class="text_27">店铺微网站</span>
              <span class="text_28">免费创建企业店铺主页</span>
            </div>
          </div>
          <div class="block_9 flex-row justify-between">
            <div class="section_6 flex-col"></div>
            <div class="text-group_10 flex-col justify-between">
              <span class="text_27">家政合单</span>
              <span class="text_28">朋友圈/微信群，一键转发</span>
            </div>
          </div>
          <div class="block_9 flex-row justify-between">
            <div class="section_6 flex-col"></div>
            <div class="text-group_10 flex-col justify-between">
              <span class="text_27">宣传海报</span>
              <span class="text_28">免费生成精美营销海报</span>
            </div>
          </div>
          <div class="block_9 flex-row justify-between">
            <div class="section_6 flex-col"></div>
            <div class="text-group_10 flex-col justify-between">
              <span class="text_27">短信群发</span>
              <span class="text_28">短信祝福、活动邀请</span>
            </div>
          </div>
        </div>
        <div class="block_14 flex-col">
          <img
            class="image_9"
            referrerpolicy="no-referrer"
            src="../assets/38.png"
          />
          <div class="box_4 flex-row justify-between">
            <div class="group_10 flex-col"></div>
            <div class="text-group_14 flex-col justify-between">
              <span class="text_35">门店管理</span>
              <span class="text_36">支持单店/多店管理</span>
            </div>
          </div>
          <div class="box_4 flex-row justify-between">
            <div class="group_10 flex-col"></div>
            <div class="text-group_14 flex-col justify-between">
              <span class="text_35">员工管理</span>
              <span class="text_36">支持多账号协同办公</span>
            </div>
          </div>
          <div class="box_4 flex-row justify-between">
            <div class="group_10 flex-col"></div>
            <div class="text-group_14 flex-col justify-between">
              <span class="text_35">业务工作台</span>
              <span class="text_36">待办事项一目了然</span>
            </div>
          </div>
          <div class="box_4 flex-row justify-between">
            <div class="group_10 flex-col"></div>
            <div class="text-group_14 flex-col justify-between">
              <span class="text_35">产品管理</span>
              <span class="text_36">上架新产品、套餐管理</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  -->

    <div class="up_neiron" id="group_1">
      <div class="pagewd">
        <div
          class="fs36 darkgray ta-c mb15"
          data-sr="enter top,move 20px,over 1s,wait .2s"
          :class="OneShow ? 'animate__animated animate__zoomIn' : ''"
        >
          系统特色
        </div>
        <div
          class="fs18 gray ta-c mb30"
          data-sr="enter top,move 20px,over 1s,wait .2s"
          :class="OneShow ? 'animate__animated animate__zoomIn' : ''"
        >
          您的家政服务平台可以为客户提供这些内容
        </div>
        <div class="up_neiron1 relative">
          <img
            src="../assets/30.png"
            width="1200"
            data-sr="enter top,move 20px,over 1s,wait .4s"
            :class="OneShow ? 'animate__animated animate__zoomIn' : ''"
          />
          <div>
            <img
              src="../assets/9.png"
              width="319"
              class="up_neiron2 up_neiron2_4"
              data-sr="enter left,move 20px,over 1s,wait .5s"
              :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
            />
            <img
              src="../assets/10.png"
              width="336"
              class="up_neiron2 up_neiron2_2"
              data-sr="enter left,move 20px,over 1s,wait .6s"
              :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
            />
            <img
              src="../assets/11.png"
              width="353"
              class="up_neiron2 up_neiron2_1"
              data-sr="enter left,move 20px,over 1s,wait .7s"
              :class="OneShow ? 'animate__animated animate__slideInLeft' : ''"
            />
            <img
              src="../assets/12.png"
              width="257"
              class="up_neiron2"
              data-sr="enter left,move 20px,over 1s,wait .8s"
              :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
            />
            <img
              src="../assets/13.png"
              width="365"
              class="up_neiron2 up_neiron2_3"
              data-sr="enter left,move 20px,over 1s,wait .9s"
              :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
            />
            <img
              src="../assets/14.png"
              width="402"
              class="up_neiron2 up_neiron2_5"
              data-sr="enter left,move 20px,over 1s,wait .10s"
              :class="OneShow ? 'animate__animated animate__slideInRight' : ''"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="up_liuc">
      <div class="pagewd">
        <div
          class="fs36 darkgray ta-c mb50"
          data-sr="enter top,move 20px,over 1s,wait .2s"
        >
          家政系统让整个流程闭环
        </div>
        <div
          class="up_liuc1 mb50"
          data-sr="enter top,move 20px,over 1s,wait .3s"
        >
          <div
            class="up_liuc2"
            :class="platformIndex == 1 ? 'on' : ''"
            @click="platformClick(1)"
          >
            用户端
          </div>
          <div
            class="up_liuc2"
            :class="platformIndex == 2 ? 'on' : ''"
            @click="platformClick(2)"
          >
            服务端
          </div>
          <!-- <div
              class="up_liuc2"
              :class="platformIndex == 3 ? 'on' : ''"
              @click="platformClick(3)"
            >
              平台管理端
            </div> -->
        </div>
        <div data-sr="enter top,move 20px,over 1s,wait .4s">
          <div class="liuchen_con" v-if="platformIndex == 1">
            <div class="up_liuc3">
              <img src="../assets/15.png" width="236" />
              <img src="../assets/16.png" width="54" class="ml40 mr40" />
              <img src="../assets/17.png" width="236" />
              <img src="../assets/16.png" width="54" class="ml40 mr40" />
              <img src="../assets/18.png" width="236" />
            </div>
          </div>
          <div class="liuchen_con" v-if="platformIndex == 2">
            <div class="up_liuc3">
              <img src="../assets/19.png" width="236" />
              <img src="../assets/16.png" width="54" class="ml40 mr40" />
              <img src="../assets/20.png" width="236" />
              <img src="../assets/16.png" width="54" class="ml40 mr40" />
              <img src="../assets/21.png" width="236" />
            </div>
          </div>
          <!-- <div class="liuchen_con" v-if="platformIndex == 3">
            <div class="swiper-container liuchen_swiper1">
              <div class="swiper-wrapper">
                <div class="swiper-slide">
                  <div class="up_liuc3">
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/img-15@2x.png"
                      width="236"
                    />
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/icon_right1.png"
                      width="54"
                      class="ml40 mr40"
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="up_liuc3">
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/img-16@2x.png"
                      width="236"
                    />
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/icon_right1.png"
                      width="54"
                      class="ml40 mr40"
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="up_liuc3">
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/img-17@2x.png"
                      width="236"
                    />
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/icon_right1.png"
                      width="54"
                      class="ml40 mr40"
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="up_liuc3">
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/img-18@2x.png"
                      width="236"
                    />
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/icon_right1.png"
                      width="54"
                      class="ml40 mr40"
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="up_liuc3">
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/img-19@2x.png"
                      width="236"
                    />
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/icon_right1.png"
                      width="54"
                      class="ml40 mr40"
                    />
                  </div>
                </div>
                <div class="swiper-slide">
                  <div class="up_liuc3">
                    <img
                      src="//jhcms01.oss-cn-hangzhou.aliyuncs.com/online_pic/pc/img-20@2x.png"
                      width="236"
                    />
                   </div>
                </div>
              </div>
              <div class="swiper-pagination"></div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="up_neiron">
      <div class="pagewd">
        <div
          class="fs36 darkgray ta-c mb50"
          data-sr="enter top,move 20px,over 1s,wait .2s"
        >
          一站式上门服务方案 有效解决各端需求不对称问题
        </div>
        <div class="up_fanan" data-sr="enter top,move 20px,over 1s,wait .2s">
          <div class="up_fanan1 flex-col align-center">
            <img src="../assets/22.png" width="76" />
            <div class="fs22 darkgray mt10">用户有需求</div>
            <div class="fs16 gray mt10">需要找人解决</div>
          </div>
          <div class="up_fanan1 flex-col align-center">
            <img src="../assets/23.png" width="76" />
            <div class="fs22 darkgray mt10">商户有服务</div>
            <div class="fs16 gray mt10">需要找到用户</div>
          </div>
          <div class="up_fanan1 flex-col align-center">
            <img src="../assets/24.png" width="76" />
            <div class="fs22 darkgray mt10">平台有流量</div>
            <div class="fs16 gray mt10">需要多样化盈利</div>
          </div>
          <div class="up_fanan1 flex-col align-center">
            <img src="../assets/25.png" width="76" />
            <div class="fs22 darkgray mt10">服务人员繁多</div>
            <div class="fs16 gray mt10">需要高效管理</div>
          </div>
        </div>
      </div>
    </div>

    <div class="doortodoor-part7 action house_bot6">
      <div class="pagewd">
        <div
          class="fs36 darkgray ta-c mb50 mt50"
          data-sr="enter top,move 20px,over 1s,wait .2s"
        >
          页面展示
        </div>
        <div class="zhanshi_tab">
          <div class="zhanshi_tab1">
            <div
              class="zhanshi_tab2"
              :class="showIndex == 1 ? 'act' : ''"
              @click="showClick(1)"
            >
              用户端
            </div>
            <div
              class="zhanshi_tab2"
              :class="showIndex == 2 ? 'act' : ''"
              @click="showClick(2)"
            >
              服务端
            </div>
          </div>
        </div>
        <div>
          <div
            class="pageshow zhanshi_li act mb50"
            data-sr="move 50px, over 1s,wait .1s, enter left"
            v-if="showIndex == 1"
          >
            <div class="flexslider" style="text-align: center">
              <ul class="slides">
                <li>
                  <div class="house_flex" style="margin-top: 20px">
                    <div>
                      <img src="../assets/1.png" />
                      <div class="house_flex2 pagebot">首页</div>
                    </div>
                    <div>
                      <img src="../assets/2.png" />
                      <div class="house_flex2 pagebot">注册登录页</div>
                    </div>
                    <div>
                      <img src="../assets/3.png" />
                      <div class="house_flex2 pagebot">下单页</div>
                    </div>
                    <div>
                      <img src="../assets/4.png" />
                      <div class="house_flex2 pagebot">订单详情页</div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
          <div
            class="pageshow zhanshi_li mb50"
            data-sr="move 50px, over 1s,wait .1s, enter left"
            v-if="showIndex == 2"
          >
            <div class="flexslider" style="text-align: center">
              <ul class="slides">
                <li>
                  <div class="house_flex" style="margin-top: 20px">
                    <div>
                      <img src="../assets/5.png" />
                      <div class="house_flex2 pagebot">订单页</div>
                    </div>
                    <div>
                      <img src="../assets/6.png" />
                      <div class="house_flex2 pagebot">服务页</div>
                    </div>
                    <div>
                      <img src="../assets/7.png" />
                      <div class="house_flex2 pagebot">信息页</div>
                    </div>
                    <div>
                      <img src="../assets/8.png" />
                      <div class="house_flex2 pagebot">我的</div>
                    </div>
                  </div>
                </li>
              </ul>
              <div class="clear"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="up_page">
      <div class="pagewd">
        <div
          class="fs36 darkgray ta-c mb50"
          data-sr="enter top,move 20px,over 1s,wait .2s"
        >
          强大的页面自定义功能，让每个平台都特色鲜明
        </div>
        <div
          class="up_fanan"
          style="margin-bottom: 0"
          data-sr="enter top,move 20px,over 1s,wait .2s"
        >
          <div class="up_page1 ta-c">
            <img src="../assets/26.png" width="124" />
            <div class="fs24 darkgray mt25">分类及icon自定义</div>
          </div>
          <div class="up_page1 ta-c">
            <img src="../assets/27.png" width="124" />
            <div class="fs24 darkgray mt25">广告位样式及位置自定义</div>
          </div>
          <div class="up_page1 ta-c">
            <img src="../assets/28.png" width="124" />
            <div class="fs24 darkgray mt25">组件显示自定义</div>
          </div>
          <div class="up_page1 ta-c">
            <img src="../assets/29.png" width="124" />
            <div class="fs24 darkgray mt25">信息流展示自定义</div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="section_32 flex-row">
      <div class="box_47 flex-col">
        <img
          class="image_42"
          referrerpolicy="no-referrer"
          src="../assets/35.png"
        />
        <span class="text_104">河南品讯网络科技有限公司</span>
        <span class="text_105">0379-61118888</span>
        <span class="paragraph_25"
          >地址：河南省洛阳市老城区青年创业大厦24层</span
        >
      </div>
      <div class="box_48 flex-col"></div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106">产品体系</span>
        <span
          class="text_107"
          @click="goOther('http://www.hnpinxun.cn/yuanchengtakan.html')"
          >远程踏勘管理系统
        </span>
        <span class="text_107" @click="goOther('http://yt.hnpinxun.cn/')"
          >友推云·电商SaaS平台</span
        >
        <span class="text_107" @click="goOther('https://fastrms.hnpinxun.cn/')"
          >视频资源管理AI工具</span
        >
        <span class="text_107" @click="goOther('http://hsxx.hnpinxun.cn/')"
          >亥时学习平台</span
        >
        <span class="text_107" @click="goOther('https://jifen.hnpinxun.cn/')"
          >文明积分银行</span
        >
        <span class="text_107" @click="goOther('https://linli.hnpinxun.cn/')"
          >邻里中心智慧平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://gcsc.yt.hnpinxun.cn/')"
          >工厂生产管理系统</span
        >
        <span class="text_107" @click="goOther('https://jdgl.yt.hnpinxun.cn/')"
          >酒店系统</span
        >
        <span class="text_107" @click="goOther('https://dysbd.yt.hnpinxun.cn/')"
          >党员双报到</span
        >
        <span class="text_107" @click="goOther('https://hcjy.yt.hnpinxun.cn/')"
          >货车道路救援系统</span
        >
        <span class="text_107" @click="goOther('https://zs.yt.hnpinxun.cn/')"
          >招商宣传</span
        >
        <span class="text_107" @click="goOther('https://hw.yt.hnpinxun.cn/')"
          >智慧环卫综合平台</span
        >
      </div>
      <div class="text-wrapper_38 flex-col">
        <span class="text_106"></span>
        <span class="text_107" @click="goOther('https://jz.yt.hnpinxun.cn/')"
          >家政行业公共服务平台</span
        >
      </div>

      <div class="box_49 flex-col"></div>
      <div class="box_50 flex-col justify-between">
        <img
          class="image_43"
          referrerpolicy="no-referrer"
          src="../assets/33.png"
        />
        <span class="text_120">微信小程序</span>
      </div>
      <div class="box_51 flex-col justify-between">
        <img
          class="image_44"
          referrerpolicy="no-referrer"
          src="../assets/34.png"
        />
        <span class="text_121">微信公众号</span>
      </div>
    </div>
    <div class="text-wrapper_19 flex-col">
      <span class="text_44"
        >Copyright&nbsp;©&nbsp;2024&nbsp;河南品讯网络科技有限公司&nbsp;版权所有豫ICP备18009817号</span
      >
    </div>
    <!--  -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      platformIndex: 1,
      highIndex: 1,
      showIndex: 1,
      OneShow: false,
      TwoShow: false,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  BeforeUnloadEvent() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    platformClick(e) {
      this.platformIndex = e;
    },
    highClick(e) {
      this.highIndex = e;
    },
    showClick(e) {
      this.showIndex = e;
    },
    freeClick() {
      window.open("http://www.hnpinxun.cn/#/", "_blank");
    },
    handleScroll() {
      const scrollPosition = window.scrollY || window.pageYOffset;
      var group1Top = document.getElementById("group_1").offsetTop;
      const isGroupVisible =
        scrollPosition >= group1Top - window.innerHeight &&
        scrollPosition < group1Top;

      if (isGroupVisible && !this.OneShow) {
        this.OneShow = true;
      } else if (scrollPosition === 0) {
        this.OneShow = false;
      }
      //
      var group2Top = document.getElementById("group_2").offsetTop;
      const isGroupVisible2 =
        scrollPosition >= group2Top - window.innerHeight &&
        scrollPosition < group2Top;

      if (isGroupVisible2 && !this.TwoShow) {
        this.TwoShow = true;
      } else if (scrollPosition === 0) {
        this.TwoShow = false;
      }
    },
    goOther(e) {
      window.open(e, "_blank");
    },
  },
};
</script>
<style scoped>
@import "../styles/css/base.css";

.up_neiron {
  background: #f7faff;
  padding: 70px 0 10px 0;
}
.up_neiron1 {
  position: relative;
}
.up_neiron2 {
  position: absolute;
  top: 46px;
  left: 579px;
}
.up_neiron2_1 {
  top: 75px;
  left: 121px;
}
.up_neiron2_2 {
  top: 194px;
  left: 17px;
}
.up_neiron2_3 {
  top: 191px;
  left: 828px;
}
.up_neiron2_4 {
  top: 380px;
  left: 0;
}
.up_neiron2_5 {
  top: 380px;
  left: 801px;
}
.up_liuc {
  padding: 80px 0 10px;
  background: #ffffff;
}
.up_liuc1 {
  border-bottom: 2px solid #f5f8fa;
  display: flex;
  align-items: center;
  justify-content: center;
}
.up_liuc2 {
  padding: 12px 20px;
  margin: 0 50px;
  position: relative;
  font-size: 20px;
  color: #666666;
  cursor: pointer;
}
.up_liuc2:hover {
  color: #0082ff;
}
.up_liuc2.on {
  color: #0082ff;
}
.up_liuc2.on::before {
  content: "";
  width: 90px;
  height: 4px;
  background: #0082ff;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 2px;
}

.liuchen_con {
  padding-bottom: 50px;
}
.up_liuc3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.swiper-pagination {
  bottom: 0;
  top: auto;
  height: 8px;
  width: 160px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  background: #d1e8ff;
  overflow: hidden;
}
.liuchen_con {
  width: 100%;
  height: 352px;
  --swiper-pagination-color: #0082ff;
}
.liuchen_con .swiper-container .swiper-pagination-progressbar {
  bottom: 0;
  top: auto;
  height: 8px;
  width: 160px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 6px;
  background: #d1e8ff;
  overflow: hidden;
}
.liuchen_con
  .swiper-container
  .swiper-pagination-progressbar
  .swiper-pagination-progressbar-fill {
  border-radius: 6px;
}
.liuchen_con .liuchen_swiper1 {
  padding-bottom: 50px;
}
.liuchen_swiper1 .up_liuc3 {
  justify-content: flex-start;
}
.up_fanan {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 90px;
}

.up_page {
  background: #f7faff;
  padding: 80px 0;
}

.house_top9 {
  color: #12a2fc;
  padding-right: 20px;
}
.house_flex {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.house_flex img {
  width: 249px;
  height: 524px;
}
.house_flex2 {
  flex: 1;
  margin-top: 30px;
}
.house_flex3 {
  display: flex;
}
.zhanshi_tab {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}
.zhanshi_tab1 {
  width: 700px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f5f8fa;
  justify-content: center;
}
.zhanshi_tab2 {
  padding: 10px 20px;
  margin: 0 100px;
  font-size: 20px;
  color: #333333;
  opacity: 0.6;
  position: relative;
  cursor: pointer;
}
.zhanshi_tab2:hover {
  opacity: 1;
}
.zhanshi_tab2.act {
  opacity: 1;
}
.zhanshi_tab2.act::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background: #10a2fc;
  width: 50px;
  height: 3px;
}
.pagebot {
  color: #333;
  font-size: 16px;
  padding-right: 20px;
}
.pagebot:last-child {
  padding-right: 0;
}
.up_page1:hover {
  transform: translateX(-10px);
}
/*  */

.section_32 {
  background-color: rgba(34, 34, 34, 1);
  width: 100%;
  height: 17.63vw;
  justify-content: flex-center;
}

.box_47 {
  width: 14.12vw;
  height: 10.73vw;
  margin: 3.02vw 0 0 18.7vw;
}

.image_42 {
  width: 2.87vw;
  height: 2.87vw;
  margin-left: 5.58vw;
}

.text_104 {
  width: 13.96vw;
  height: 1.1vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.04vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.05vw;
  margin-top: 0.73vw;
}

.text_105 {
  width: 14.02vw;
  height: 1.25vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 1.56vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 1.57vw;
  margin-top: 0.94vw;
}

.paragraph_25 {
  width: 14.07vw;
  height: 1.88vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  line-height: 1.25vw;
  margin: 1.97vw 0 0 0.05vw;
}

.box_48 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.28vw 0 0 3.26vw;
}

.text-wrapper_38 {
  width: 5.68vw;
  height: 9.38vw;
  margin: 1.9vw 0 0 5.36vw;
}

.text_106 {
  width: 3.7vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_107 {
  width: 4.8vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.4vw 0 0 0.05vw;
  cursor: pointer;
}
.text_107:hover {
  color: #0b74ef;
}

.text-wrapper_39 {
  width: 3.7vw;
  height: 9.43vw;
  margin: 3.8vw 0 0 5.31vw;
}

.text_111 {
  width: 3.7vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: 700;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
}

.text_112 {
  width: 2.66vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.71vw 0 0 0.05vw;
}

.text_113 {
  width: 3.55vw;
  height: 0.84vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.text_114 {
  width: 3.6vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.19vw 0 0 0.05vw;
}

.box_49 {
  background-color: rgba(255, 255, 255, 0.12);
  width: 0.06vw;
  height: 10.42vw;
  margin: 3.22vw 0 0 5.41vw;
}

.box_50 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 0 0 4.3vw;
}

.image_43 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_120 {
  width: 4.48vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}

.box_51 {
  width: 6.78vw;
  height: 8.91vw;
  margin: 4.2vw 12.55vw 0 1.25vw;
}

.image_44 {
  width: 6.78vw;
  height: 6.78vw;
}

.text_121 {
  width: 4.54vw;
  height: 0.79vw;
  overflow-wrap: break-word;
  color: rgba(223, 223, 223, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 1.14vw;
}
.text-wrapper_19 {
  background-color: rgba(59, 59, 59, 1);
  height: 3.49vw;
  margin-top: -0.05vw;
}

.text_44 {
  width: 28.6vw;
  height: 0.89vw;
  overflow-wrap: break-word;
  color: rgba(153, 153, 153, 1);
  font-size: 0.83vw;
  font-family: MicrosoftYaHei;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  line-height: 0.84vw;
  margin: 1.35vw 0 0 35.67vw;
}
/*  */
.block_1 {
  height: 37.5vw;
  background: url("/src/assets/39.png") 100% no-repeat;
  background-size: 100% 100%;
}

.text_1 {
  width: 28.91vw;
  height: 1.67vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 1.66vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  margin: 10.41vw 0 0 18.75vw;
}

.text_2 {
  width: 28.13vw;
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(0, 0, 0, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  margin: 1.56vw 0 0 18.75vw;
}

.paragraph_1 {
  width: 23.44vw;
  height: 2.4vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  margin: 1.56vw 0 0 18.75vw;
}

.box_1 {
  box-shadow: 0px 4px 6px 0px rgba(47, 108, 255, 0.35);
  height: 2.61vw;
  width: 8.34vw;
  margin: 4.06vw 0 10.1vw 18.75vw;
}

.text-wrapper_1 {
  background-color: rgba(11, 109, 236, 1);
  border-radius: 5px;
  height: 2.61vw;
  width: 8.34vw;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  line-height: 2.61vw;
  cursor: pointer;
}
.text-wrapper_1:hover {
  transform: scale(1.1);
}
.block_2 {
  position: relative;
  margin-bottom: 0.06vw;
}

.text-group_1 {
  height: 7.09vw;
  margin: 4.16vw 0 0 0vw;
}

.text_4 {
  height: 3.13vw;
  overflow-wrap: break-word;
  color: rgba(11, 109, 236, 1);
  font-size: 3.12vw;
  font-family: MiSans-Medium;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
}

.paragraph_2 {
  height: 2.4vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: center;
  margin-top: 1.57vw;
}

.block_3 {
  width: 62.5vw;
  height: 3.13vw;
  margin: 3.12vw auto 0 auto;
}

.text-wrapper_2 {
  background-image: linear-gradient(
    90deg,
    rgba(84, 136, 255, 1) 0,
    rgba(140, 189, 255, 1) 100%
  );
  border-radius: 8px;
  height: 3.13vw;
  width: 7.56vw;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MiSans-Demibold;
  font-weight: normal;
  text-align: center;
  line-height: 3.13vw;
}
.image_1 {
  width: 1.78vw;
  height: 0.63vw;
  margin: 1.25vw 0 0 0.83vw;
}

.text-wrapper_3 {
  background-image: linear-gradient(
    90deg,
    rgba(84, 136, 255, 1) 0,
    rgba(140, 189, 255, 1) 100%
  );
  border-radius: 8px;
  height: 3.13vw;
  width: 7.56vw;
  color: rgba(255, 255, 255, 1);
  font-size: 0.83vw;
  font-family: MiSans-Demibold;
  font-weight: normal;
  text-align: center;
  line-height: 3.13vw;
  margin-left: 0.84vw;
}

.block_4 {
  width: 62.5vw;
  height: 19.8vw;
  margin: 1.56vw auto 3.125vw auto;
}

.block_5 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 19.8vw;
  border: 1px solid #dedede;
}
.block_5:hover {
  transform: translateY(-10px);
}

.image_6 {
  width: 14.85vw;
  height: 3.13vw;
  border-radius: 0.625vw 0.625vw 0 0;
}

.section_1 {
  width: 8.49vw;
  height: 2.19vw;
  margin: 1.56vw 0 0 1.56vw;
  align-items: baseline;
}

.group_1 {
  background-color: rgba(11, 109, 236, 1);
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.27vw;
}

.text-group_2 {
  width: 7.3vw;
  height: 2.19vw;
}

.text_11 {
  width: 3.75vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.text_12 {
  width: 7.3vw;
  height: 0.73vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin-top: 0.53vw;
}

.block_6 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 19.8vw;
  margin-left: 1.05vw;
  border: 1px solid #dedede;
}
.block_6:hover {
  transform: translateY(-10px);
}
.image_7 {
  width: 14.85vw;
  height: 3.13vw;
  border-radius: 0.625vw 0.625vw 0 0;
}

.group_4 {
  width: 7.77vw;
  height: 2.19vw;
  margin: 1.56vw 0 0 1.56vw;
  align-items: baseline;
}

.box_2 {
  background-color: rgba(11, 109, 236, 1);
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.27vw;
}

.text-group_6 {
  width: 6.57vw;
  height: 2.19vw;
}

.text_19 {
  width: 4.69vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.text_20 {
  width: 6.57vw;
  height: 0.73vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin-top: 0.53vw;
}

.block_8 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 19.8vw;
  margin-left: 1.05vw;
  border: 1px solid #dedede;
}
.block_8:hover {
  transform: translateY(-10px);
}

.image_8 {
  width: 14.85vw;
  height: 3.13vw;
  border-radius: 0.625vw 0.625vw 0 0;
}

.block_9 {
  width: 8.49vw;
  height: 2.19vw;
  margin: 1.56vw 0 0 1.56vw;
  align-items: baseline;
}

.section_6 {
  background-color: rgba(11, 109, 236, 1);
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.27vw;
}

.text-group_10 {
  width: 7.3vw;
  height: 2.19vw;
}

.text_27 {
  width: 4.69vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.text_28 {
  width: 7.3vw;
  height: 0.73vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin-top: 0.53vw;
}

.block_14 {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 10px;
  width: 14.85vw;
  height: 19.8vw;
  margin-left: 1.05vw;
  border: 1px solid #dedede;
}
.block_14:hover {
  transform: translateY(-10px);
}

.image_9 {
  width: 14.85vw;
  height: 3.13vw;
  border-radius: 0.625vw 0.625vw 0 0;
}

.box_4 {
  width: 7.4vw;
  height: 2.19vw;
  margin: 1.56vw 0 0 1.56vw;
  align-items: baseline;
}

.group_10 {
  background-color: rgba(11, 109, 236, 1);
  border-radius: 50%;
  width: 0.42vw;
  height: 0.42vw;
  margin-top: 0.27vw;
}

.text-group_14 {
  width: 6.2vw;
  height: 2.19vw;
}

.text_35 {
  width: 3.75vw;
  height: 0.94vw;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 0.93vw;
  font-family: PingFang SC-Semibold;
  font-weight: 600;
  text-align: left;
  white-space: nowrap;
}

.text_36 {
  width: 6.2vw;
  height: 0.73vw;
  overflow-wrap: break-word;
  color: rgba(102, 102, 102, 1);
  font-size: 0.72vw;
  font-family: PingFang SC-Regular;
  font-weight: normal;
  text-align: left;
  white-space: nowrap;
  margin-top: 0.53vw;
}
/*  */
.up_fanan1 {
  background: #ffffff;
  box-shadow: 0 0 50px 0 rgba(222, 229, 235, 0.48);
  border-radius: 12px;
  width: 280px;
  height: 226px;
  transition: all 0.3s;
  text-align: center;
  padding: 40px 20px 0;
}
.up_fanan1:hover {
  transform: translateX(-10px);
}
</style>
